@import url('https://fonts.googleapis.com/css2?family=Kufam:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Fonts */
:root {
    --font-default: 'Kufam', sans-serif;
    --font-secondary: "Work Sans", sans-serif;
}

/* Colors */
:root {
    --color-default: #100317;
    --color-primary: #5C0F87;
    --color-secondary: #FAEA58;

    /* --color-deselected:  */
    --color-disabled: #C7C7C7;
    --color-black: #000000;
    --color-white: #ffffff;

}

body {
    font-family: var(--font-default);
    color: var(--color-default);
}

/* general css */
ul {
    list-style: none;
    padding: 0 !important;
}

.p,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 !important;
}


/* button loader */

.button-loader {
    --color: #ffffff;
    --size: 24px;
    position: relative;
    width: var(--size);
    height: var(--size);
}


.button-loader::after {
    content: '';
    width: 25px;
    height: 25px;
    position: absolute;
    top: 7px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border: 3px solid var(--color);
    border-bottom: 3px solid transparent;
    border-radius: 100%;
    -webkit-animation: keyframes-rotate .8s infinite linear;
    animation: keyframes-rotate .8s infinite linear;
}


@-webkit-keyframes keyframes-rotate {
    0% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


@keyframes keyframes-rotate {
    0% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* button loader end */



/* full screen loader css */

@-webkit-keyframes square-animation {
    0% {
        left: 0;
        top: 0;
    }

    10.5% {
        left: 0;
        top: 0;
    }

    12.5% {
        left: 32px;
        top: 0;
    }

    23% {
        left: 32px;
        top: 0;
    }

    25% {
        left: 64px;
        top: 0;
    }

    35.5% {
        left: 64px;
        top: 0;
    }

    37.5% {
        left: 64px;
        top: 32px;
    }

    48% {
        left: 64px;
        top: 32px;
    }

    50% {
        left: 32px;
        top: 32px;
    }

    60.5% {
        left: 32px;
        top: 32px;
    }

    62.5% {
        left: 32px;
        top: 64px;
    }

    73% {
        left: 32px;
        top: 64px;
    }

    75% {
        left: 0;
        top: 64px;
    }

    85.5% {
        left: 0;
        top: 64px;
    }

    87.5% {
        left: 0;
        top: 32px;
    }

    98% {
        left: 0;
        top: 32px;
    }

    100% {
        left: 0;
        top: 0;
    }
}

@keyframes square-animation {
    0% {
        left: 0;
        top: 0;
    }

    10.5% {
        left: 0;
        top: 0;
    }

    12.5% {
        left: 32px;
        top: 0;
    }

    23% {
        left: 32px;
        top: 0;
    }

    25% {
        left: 64px;
        top: 0;
    }

    35.5% {
        left: 64px;
        top: 0;
    }

    37.5% {
        left: 64px;
        top: 32px;
    }

    48% {
        left: 64px;
        top: 32px;
    }

    50% {
        left: 32px;
        top: 32px;
    }

    60.5% {
        left: 32px;
        top: 32px;
    }

    62.5% {
        left: 32px;
        top: 64px;
    }

    73% {
        left: 32px;
        top: 64px;
    }

    75% {
        left: 0;
        top: 64px;
    }

    85.5% {
        left: 0;
        top: 64px;
    }

    87.5% {
        left: 0;
        top: 32px;
    }

    98% {
        left: 0;
        top: 32px;
    }

    100% {
        left: 0;
        top: 0;
    }
}

.full-screen-loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    background: #000000c7;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

}

.full-screen-loader {
    position: relative;
    width: 96px;
    height: 96px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    margin: auto;
    top: 43%;
}

.full-screen-loader .loader-square {
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 28px;
    margin: 2px;
    border-radius: 0px;
    background: white;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    -webkit-animation: square-animation 10s ease-in-out infinite both;
    animation: square-animation 10s ease-in-out infinite both;
}

.full-screen-loader .loader-square:nth-of-type(0) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

.full-screen-loader .loader-square:nth-of-type(1) {
    -webkit-animation-delay: -1.4285714286s;
    animation-delay: -1.4285714286s;
}

.full-screen-loader .loader-square:nth-of-type(2) {
    -webkit-animation-delay: -2.8571428571s;
    animation-delay: -2.8571428571s;
}

.full-screen-loader .loader-square:nth-of-type(3) {
    -webkit-animation-delay: -4.2857142857s;
    animation-delay: -4.2857142857s;
}

.full-screen-loader .loader-square:nth-of-type(4) {
    -webkit-animation-delay: -5.7142857143s;
    animation-delay: -5.7142857143s;
}

.full-screen-loader .loader-square:nth-of-type(5) {
    -webkit-animation-delay: -7.1428571429s;
    animation-delay: -7.1428571429s;
}

.full-screen-loader .loader-square:nth-of-type(6) {
    -webkit-animation-delay: -8.5714285714s;
    animation-delay: -8.5714285714s;
}

.full-screen-loader .loader-square:nth-of-type(7) {
    -webkit-animation-delay: -10s;
    animation-delay: -10s;
}

/* full screen  loader css end */

/* editor css */
.ck.ck-balloon-panel.ck-balloon-panel_visible {
    opacity: 0;
}

.ck.ck-editor__editable {
    min-height: 190px;
}

/*  */


.sidebar-main-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 200px;
    background-color: #F7F7F7;

    padding: 1.5rem;
}

.content-wrapper {
    margin-left: 190px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.topheader-wrapper {
    height: 110px;
    padding: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* justify-content: space-between; */
    background: #ffffff;
    border-bottom: 1px solid #DEDEDE;
}

.topheader-wrapper h3 {
    padding-top: 10px;
}

.topheader-main-wrapper {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.nounderline {
    text-decoration: none;
}

.maxwidth-full {
    max-width: 100% !important;
    width: 100%;
}

.height-same {
    max-height: 40px;
    height: 100%;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.back-btn-wrapper a svg {
    border: 1px solid;
    color: #000;
    width: 40px;
    height: 40px;
    padding: 3px;
    margin-right: 10px;
}


/* sidebar */

ul.sidebar-menu li a,
.nav-color {
    color: #929292;
}

ul.sidebar-menu li a.active {
    color: #5C0F87;
}

.logo-main {
    padding: 0 24px 30px;
}

.accordion-title-sidebar.active {
    color: #5C0F87;
}

.accordion-title-sidebar.active .arrow-dd {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.accordion-title-sidebar .arrow-dd {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

ul.sidebar-menu li {
    padding: 8px 0;
}

.sidebar-menu .accordion-body ul li {
    list-style: disc;
    margin-left: 8px;
    color: #D9D9D9;
}

/* state  */
.thisActive {
    height: 24px;
    min-width: 22px;
    line-height: 0;
    border-radius: 6px;
    cursor: default;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-transform: capitalize;
    padding: 0px 8px;
    color: rgb(22, 101, 52);
    font-size: 12px;
    background-color: rgb(240, 253, 244);
    font-weight: 600;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.thisInactive {
    height: 24px;
    min-width: 22px;
    line-height: 0;
    border-radius: 6px;
    cursor: default;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-transform: capitalize;
    padding: 0px 8px;
    color: rgb(150 150 150);
    font-size: 12px;
    background-color: rgb(235, 235, 235);
    font-weight: 600;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.PrimaryButton {
    color: #fff;
    padding: 4px 24px;
    /* width: 173px; */
    min-width: 160px;
    height: 40px;
    border: 1px solid #5C0F87;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 138%;
    background: #5C0F87;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.PrimaryButton:active {
    background: #530d79;
}

.PrimaryButton:hover {
    background: #530d79;
}

.SecondaryButton {
    color: #5C0F87;
    padding: 4px 24px;
    /* width: 173px; */
    height: 40px;
    border: 1px solid #5C0F87;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 138%;
    background: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.SecondaryButton:active {
    background: #5C0F871f;
}

.SecondaryButton:hover {
    background: #5C0F871f;
}

.SmallButton {
    border: 1px solid #949494;
    border-radius: 2.31704px;
    background: transparent;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 10px;
    /* or 69% */
    color: #292929;
    padding: 4px 10px;
}

.DeleteButton {
    border: 1px solid #C64444;
    border-radius: 4px;
    background: transparent;
    color: #C64444;
    font-weight: 600;
    font-size: 14px;
    line-height: 143.02%;
    padding: 3px 9px;
}

.DeleteButton:active {
    background: #c644441f;
}

.DeleteButton:hover {
    background: #c644441f;
}

/* .user-table-pagination ul {
    justify-content: center;
} */
.thisActiveUser .MuiSwitch-switchBase .MuiSwitch-thumb {
    color: #1FA00A;

}

.thisActiveUser .MuiSwitch-track {
    background: #1FA00A;
}

.thisInactiveUser .MuiSwitch-switchBase .MuiSwitch-thumb {
    color: rgb(150 150 150);

}

.thisInactiveUser .MuiSwitch-track {
    background: rgb(235, 235, 235);
}

.user-content,
.bookings-wrapper,
.tour-wrapper {
    width: 90%;
    margin: 0 auto;
}

.action-dropdown-btn .dropdown-toggle::after {
    display: none;
}

.content-wrapper {
    font-family: 'Kufam';
}

/* phone number inputs */

.user-content .PhoneInput--focus .PhoneInputInput {
    outline: 0;
    border: 0;
}

.user-content .PhoneInput .PhoneInputInput {
    background: #fff;
    border: 1px solid #c2c8cc;
    border-radius: 0 4px 4px 0;
    /* color: #646464; */
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding: 11px;
}

.user-content .PhoneInput .PhoneInputCountry {
    background: #fff;
    border: 1px solid #c2c8cc;
    border-radius: 4px 0 0 4px;
    margin-right: 0 !important;
    width: 50px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-right: 0;
}

/* tabel pagination */

.pagination-wrapper {
    margin: 40px 0;
}

.pagination-wrapper ul {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.custom-pagination button {
    margin: 0;
    border-radius: 0;
    border: 1px solid #E0E2E7;
    font-family: 'Kufam';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #232325;
    width: 42px;
    height: 38px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* padding-top: 3px; */
}

.custom-pagination ul li:first-child button {
    border: 1px solid #E0E2E7;
    color: #5C0F87;
    border-radius: 4px 0 0 4px;
    padding: 0;
}

.custom-pagination ul li:last-child button {
    border: 1px solid #E0E2E7;
    color: #5C0F87;
    border-radius: 0 4px 4px 0;
    padding: 0;
}

.custom-pagination .css-x21ecl-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background: #5C0F87;
    color: #FFFFFF;
}

.custom-pagination .css-x21ecl-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover {
    background: #530d79;
}


.custom-pagination {
    font-size: 16px;
}

.user-content h5 {
    padding-bottom: 15px;
}

.user-content .details-title-left,
.user-content .details-title-right {
    padding-bottom: 30px;
}

/* table input */
.input-title {
    font-weight: 600;
    padding: 5px 0;
}

.table-inputs-content {
    font-family: 'Kufam';
}

.table-inputs-content input {
    padding: 0;
    font-family: 'Kufam';
}



/* table title */
.table-title-bold {
    font-family: 'Kufam';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 24px !important;
}

.sub-page-details-wrapper .sub-page-title {
    padding: 15px 0 8px;
    margin-bottom: 15px !important;
    font-family: 'Kufam';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 138%;
    /* or 19px */
    color: #626262;
}

/* table data */
.booking-table-body-wrapper .table-data.booking-table-data-id,
.tours-table-body-wrapper .table-data.tours-table-data-id {
    font-family: 'Kufam';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
}

.booking-table-body-wrapper .table-data {
    font-family: 'Kufam';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}

.table-inputs-content {
    width: 100%;
}

/* .table-inputs-content>div {
    width: 100%;
} */

.table-input-date-selector {
    border: 1px solid #C2C8CC;
    border-radius: 4px;
    padding: 8px 15px;
    width: 275px;
}

.creae-tour-date-selected.table-input-date-selector {
    width: 100%;
}

.table-inputs-with-sub-text .lite-color {
    font-family: 'Kufam';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 138%;
}

/* edit button meta info */
.edit-button-wrapper .SmallButton {
    padding: 9px;
    margin: 7px 0;
}

.company-details-address .ql-disabled {
    color: rgba(0, 0, 0, 0.38);
}

/* user info box */
.completed {
    font-family: 'Kufam';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
    color: #166534;
}

.lite-color {
    color: #6B7280;
}

.travel-history-box {
    padding: 20px;
    background: #FFFFFF;
    border: 1px solid #D1D5DB;
    border-radius: 8px;
    margin-bottom: 15px;
    max-width: 650px;
    width: 100%;
}

.submit-reset-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
    gap: 18px;
    margin: 32px 0;
}

/* booking create page */
.booking-form-wrapper p {
    margin-bottom: 1rem;
}

.booking-phone-input {
    border: 1px solid #ccc;
    border-radius: 0.3rem;
    padding: 0 0 0 0.5rem;
}

.booking-phone-input input.PhoneInputInput {
    border: none;
    padding: 0.5rem 0.5rem 0.5rem 0;
}

.MuiFormControl-root {
    max-width: 250px;
    width: 100%;
}


/* upload file css */
#form-file-upload {
    height: 100%;
    width: 100%;
    max-width: 100%;
    /* text-align: center; */
    position: relative;
}

#form-file-upload .MuiFormControl-root {
    max-width: 100%;
    margin: 0;
    margin-bottom: 2rem;
}

#input-file-upload {
    display: none;
}

.label-file-upload {
    height: 100%;
    min-height: 130px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 0.5rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
    padding: 15px;
}

.label-file-upload.drag-active {
    background-color: #ffffff;
}

.upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
}

.upload-button:hover {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
}

#drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

/* setting page */
.setting-language-wrapper {
    background: #FFFFFF;
    border: 0.5px solid #D3D3D3;
    border-radius: 8px;
    padding: 25px;
}

h5.sub-title-language {
    font-weight: 500;
    font-size: 16px;
    line-height: 138%;
    /* identical to box height, or 22px */
    color: #000000;
}

h6.sub-title-language {
    font-weight: 500;
    font-size: 16px;
    line-height: 138%;
    /* identical to box height, or 22px */
    color: #000000;
}

span.title-small-text {
    font-weight: 600;
    font-size: 10px;
    line-height: 138%;
    /* or 14px */
    color: #8E8E8E;
    /* width: 80%; */
    display: block;
}

/* report */
.report-generate-inputs {
    gap: 1.25rem;
}

/* testimony */
.testimony-img {
    width: 40px;
    height: 40px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    border-radius: 50%;
}

.Testimony-Details .testimony-img {
    width: 100%;
    height: 250px;
    border-radius: 0;
}

.table-data-bold {
    font-weight: 600;
}

/* blog */
.create-blog-description-wrapper .ql-container {
    height: 250px;
}

.blog-details-wrapper .create-blog-description-wrapper .ql-container {
    height: 100%;
}

.blog-details-img-wrapper img {
    object-fit: cover;
    object-position: center;
    max-width: 100%;
    width: 100%;
    max-height: 230px;
}

.blog-details-img-wrapper {
    overflow: hidden;
}

/* create tour page */
.below-note {
    text-align: center;
    display: block;
    color: #6F727A;
    font-family: Kufam;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
}

.sub-page-details-wrapper .upload-button {
    background-color: transparent;
    color: #5C0F87;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: Kufam;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
}

.around-border {
    border: 1px solid #D3D3D3;
    border-radius: 8px;
    padding: 24px 42px 26px;
    margin-bottom: 30px;
}

.create-tour-icon-wrapper {
    width: 100% !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
}

.total-traveler-count {
    margin: 16px 0 30px;
}

.PhoneInputCountry {
    border-right: 1px solid #D3D3D3;
    padding-right: var(--PhoneInputCountrySelect-marginRight);
}

.total-traveler-count h5 {
    font-family: 'Kufam';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 138%;
}

.adult-details-wrapper-count h5 {
    font-family: 'Kufam';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 138%;
    padding-bottom: 15px;
}

.booking-form-wrapper {
    font-size: 14px;
}

.adult-details-wrapper-count {
    margin-top: 20px;
}

.inclusion-exclusion-checkbox:checked {
    accent-color: #5C0F87;
}

.inclusion-exclusion-wrapper .inclusion-exclution-text {
    font-family: 'Kufam';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 230%;
    padding-left: 8px;
    padding-top: 3px;
}

.inclusion-exclusion-list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.inclusion-exclusion-list input.inclusion-exclusion-checkbox {
    width: 20px;
    height: 20px;
}

button.back-to-top-button {
    background: none;
    border: none;
    padding: 0;
    width: 40px;
    height: 40px;
}

button.back-to-top-button svg {
    width: 40px;
    height: 40px;
    color: #5C0F87;
}

button.remove-button-location-img {
    border: 0;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    margin-left: auto;
    text-decoration: underline;
    font-size: 12px;
    color: #929292;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 6px;
    right: 6px;
    background: transparent;
}

button.remove-button-location-img:hover {
    color: #5C0F87;
    text-decoration: underline;
}

.location-img-wrap {
    position: relative;
}

/*  */
.submit-button-sub-page {
    max-width: 150px;
    margin-left: auto;
}

.sub-page-details-wrapper .viwe-page-data {
    border: 1px solid #D3D3D3;
    padding: 8.5px 14px;
    margin-bottom: 1rem !important;
    border-radius: 4px;
    min-height: 43px;
}

.gallery-wrapper .image-preview {
    position: relative;
    margin: 8px;
    /* width: 175px;
    max-height: 90px;
    min-height: 90px; */
    /* overflow: hidden; */
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}

.view-tour-details-wrapper .gallery-wrapper .image-preview {
    width: 175px;
    min-height: 90px;
    max-height: 90px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}

/* error message */
.error-msg {
    color: red;
    font-size: 12px;
    text-transform: capitalize;
}

/* category edit tours */

/* .tour-wrapper .css-pi50ht-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: var(--color-primary);
} */

/* .tour-wrapper .css-1aquho2-MuiTabs-indicator {
    background-color: var(--color-primary);
} */

.user-table-content .dropdown-item.active,
.user-table-content .dropdown-item:active {
    background-color: #5c0f873b;
}

/* banner */
.user-content .small-title {
    font-size: 15px;
    font-weight: 500;
    color: #929292;
}

.label-file-upload img {
    width: 100%;
    height: 200px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}

/* edit page current image preview */

.current-image {
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    position: relative;
}

.current-image img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.closebutton {
    border: 0;
    background: rgb(33 37 41 / 50%);
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    border-radius: 0 0 0 8px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.closebutton:hover {
    color: #dfdfdf;
}


/* privacy policy  */

.policy-description-wrapper.policy-arabic .ql-picker-label {
    padding-right: 17px;
    padding-left: 2px;
}

/* faq page */
.faq-quetions-wrapper.around-border {
    padding: 18px 20px 20px;
    margin-bottom: 12px;
}

.faq-page-wrapper .add-button {
    text-align: start;
}

.faq-page-wrapper .around-border {
    margin-bottom: 15px;
}

.faq-main-block-wrapper .remove-button {
    border: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    text-decoration: underline;
    font-size: 12px;
    color: #5C0F87;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.faq-main-block-wrapper .remove-button:hover {
    color: #5c0f87c9;
}

.error-msg-dot::before {
    content: "*";
    position: absolute;
    right: 5px;
    top: 5px;
    color: red;
}


/* additional information components */
.additional-information-content-wrapper .DeleteButton {
    font-size: 12px;
    display: none;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.additional-information-content-wrapper .menu-items-li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    line-height: 34px;
}

.additional-information-content-wrapper .menu-items-li:hover .DeleteButton {
    display: block;
}

.additional-information-wrapper .sub-page-title {
    margin-bottom: 0px !important;
}

.company-details-address .ql-container.ql-snow {
    border: 1px solid #C2C8CC;
    border-radius: 4px;
}

/* page not found  */
.page-not-found-wrapper {
    /* background: linear-gradient(0deg, #5c0f872e 0%, var(--color-white) 50%); */
    background: #fff;
    /* position: fixed; */
    /* top: 0;
    left: 0;
    right: 0;
    bottom: 0; */
}

.page-not-found-wrapper .content-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100vh;
    /* margin: 0; */
}

.page-not-found-wrapper .content-wrapper h1 {
    color: #5C0F87;
    font-size: 146px;
    line-height: 75%;
    font-weight: 400;
    margin: 0;
}

.page-not-found-wrapper .content-wrapper h4 {
    font-weight: 500;
    font-size: 42px;
    line-height: 55px;
    text-align: center;
    color: #4B4B4B;
    margin: 0;
}

.page-not-found-wrapper .content-wrapper p {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #949494;
    margin: 0;
}

.page-not-found-wrapper .PrimaryButton {
    margin: 25px 0 0;
    width: 350px;
}